<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {computed} from "vue";
import {useStore} from "vuex";
import PercentGraph from '@/components/PercentGraph';
import EmptyGraph from '@/components/EmptyGraph';
import Objective9 from '@/components/Objective9';

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country', 'items', 'indices'],
  setup(props){
    const store = useStore();
    const values = store.getters.getDataForObjectiveGraphs(props.indices, props.items, 9)
    const textIndi = computed( () => store.getters.getIndicatorText(2,9,props.country.id));
    const all    = store.state.indexGuide.filter(d => d.axis == 2 && d.objective == 9);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      getIcon : store.getters.getIcon,
      values,
      textIndi,
      all
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
    PercentGraph,
    Objective9,
    EmptyGraph
  }
}
</script>


<template>
  <div class="row">
    <objective-9/>
    <div class="col-sm-10 offset-sm-1 mb-4 pb-4">        
      <div v-html="textIndi"></div>
    </div>
    <div v-for="index of all" :key="`tmp-${index.db_name}`" class="col-12">
        <percent-graph v-if="values.find(d => index.db_name == d.column)" :indice="values.find(d => index.db_name == d.column)" :country="country" />
        <empty-graph v-else :indice="index" :country="country" />
      </div>
  </div>
</template>